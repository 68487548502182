form{
    label{
        color: #75787d;
        font-size: 0.75rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;
    }
    .labelfocus{
        color: #000;
    }
    .form-group{
        margin-bottom: 1.5rem;
    }

    .form-control{
        border: 1px solid #b3b3b3;
        border-color: #b3b3b3 #ccc #d9d9d9;
        // font-size: .9rem !important;
        font-weight: 300 !important;

        color: #75787d;

        &::-webkit-input-placeholder {
           color: #999;
        }

        &:-moz-placeholder { /* Firefox 18- */
           color: #999;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
           color: #999;
        }

        &:-ms-input-placeholder {
           color: #999;
        }

        &:focus{
            border-color: #000;
        }
    }
    .form-control-position{
        position: absolute;
        top: 2px;
        right: 0;
        z-index: 2;
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
    }
    .has-icon-left{
        .form-control-position{
            right: auto;
            left: inherit;
        }
        .form-control{
            padding-right: .85rem;
            padding-left: 2.95rem;
        }
    }
    .has-icon-right{
        .form-control{
            padding-right: 2.5rem;
        }
    }
    .form-section{
        color: theme-color('dark');
        line-height: 3rem;
        font-size: 1.2rem;
        letter-spacing: 0.5px;
        font-weight: 400;
        margin-bottom: 20px;
        border-bottom: 1px solid theme-color('dark');

        i{
            font-size: 20px;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    /* .form-body{
        overflow: hidden;
    } */

    .form-actions{
        border-top: 1px solid darken($body-bg, 10%);
        padding: 20px 0;
        margin-top: 20px;

        &.filled{
            background-color: $body-bg;
        }
        &.center{
            text-align: center;
        }
        &.right{
            text-align: right;
        }

        &.top{
            border-top: 0;
            border-bottom: 1px solid darken($body-bg, 10%);
            margin-top: 0;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            .buttons-group{
                float: left !important;
                margin-bottom: 10px;
            }
        }
    }

    &.form-horizontal{
        .form-group{
            @include media-breakpoint-up(sm) {
                .label-control{
                    text-align: right;
                }
            }
        }
    }

    &.row-separator{
        .form-group{
            margin: 0;
            border-bottom: 1px solid darken($body-bg, 3%);
            .label-control{
                padding-top: 1.5rem;
            }
            > div{
                padding: 1.2rem;
                padding-right: 0;
            }
            &.last{
                border-bottom: 0;
            }
        }
    }

    &.form-bordered{
        .form-group{
            margin: 0;
            border-bottom: 1px solid darken($body-bg, 3%);
            .label-control{
                padding-top: 1.5rem;
            }
            > div{
                padding: 1.2rem;
                padding-right: 0;
                border-left: 1px solid darken($body-bg, 3%);
            }
            &.last{
                border-bottom: 0;
            }
        }
    }

    &.striped-rows{
        .form-group{
            margin: 0;

            .label-control{
                padding-top: 1.5rem;
            }

            > div{
                padding: 1.2rem;
                padding-right: 0;
            }

            &:nth-child(even){
                background-color: $body-bg;
            }
        }
    }

    &.striped-labels{
        .form-group{
            margin: 0;

            .label-control{
                padding-top: 1.5rem;
            }

            > div{
                padding: 1.2rem;
                padding-right: 0;
                background-color: white;
            }

            &:nth-child(even){
                background-color: $body-bg;
            }
        }
    }
}

select.form-control {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}


.form-control{
    &:disabled,
    &[readonly]{
        background-color: #ECEFF1;
    }
}
.input-group-addon {
    background-color: #ECEFF1;
}

.form-control-xl{
    padding: 0.8rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control-xl:not([size]):not([multiple]){
    height: calc(3.25rem + 2px);
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"]{
    margin-top: -4px;
    margin-left: -1rem;
}

.form-control-xl, .input-group-xl > .form-control, .input-group-xl > .input-group-addon, .input-group-xl > .input-group-btn > .btn{
    padding: 0.8rem 1rem;
}

.ng-touched.ng-invalid{
    border-color: theme-color("danger") !important;
}
form .form-control {
    &.ng-touched.ng-invalid{
        border-color: theme-color("danger");
    }
}
