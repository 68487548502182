// ================================================================================================
//   File Name: pallette.scss
//   Description: Custom color system styles, includes background, border and text colors
// ================================================================================================
//  ================================================================================================
//  WARNING: PLEASE DO NOT CHANGE THIS VARIABLE FILE.
//  THIS FILE WILL GET OVERWRITTEN WITH EACH APEX TEMPLATE RELEASE.
//  TIP:
//  We suggest you to use this (assets/scss/colors/palette.scss) file for overriding color variables.
//  ================================================================================================
@import "../mixins/colors";
// usage: color("name_of_color", "type_of_color")
// to avoid to repeating map-get($colors, ...)
@function color-function($color, $type) {
    @if map-has-key($colors, $color) {
        $curr_color: map-get($colors, $color);
        @if map-has-key($curr_color, $type) {
            @return map-get($curr_color, $type);
        }
    }
    @return null;
}

// Color palettes
@import "app-colors";
@each $gcolor_name,
$gcolor in $gradient-colors {
    $start-color: map-get($gcolor, start-color);
    $end-color: map-get($gcolor, end-color);
    .#{$gcolor_name} {
        @include gradient-directional($start-color, $end-color);
    }
    .btn.#{$gcolor_name} {
        &:active,
        &.active {
            @include gradient-directional($start-color, $end-color);
        }
    }
}

@each $color_name,
$color in $colors {
    @each $color_type,
    $color_value in $color {
        @if $color_type=="base" {
            .#{$color_name} {
                color: $color_value !important;
            }
            .bg-#{$color_name} {
                background-color: $color_value !important;
                .card-header,
                .card-footer {
                    background-color: transparent;
                }
            }
            .toast-#{$color_name} {
                background-color: $color_value;
            }
            .alert-#{$color_name} {
                border-color: $color_value !important;
                background-color: lighten($color_value, 7%) !important;
                color: darken($color_value, 35%) !important;
                .alert-link {
                    color: darken($color_value, 43%) !important;
                }
            }
            .border-#{$color_name} {
                border-color: $color_value;
            }
            .border-top-#{$color_name} {
                border-top-color: $color_value;
            }
            .border-bottom-#{$color_name} {
                border-bottom-color: $color_value;
            }
            .border-left-#{$color_name} {
                border-left-color: $color_value;
            }
            .border-right-#{$color_name} {
                border-right-color: $color_value;
            }
            .badge-#{$color_name} {
                background-color: $color_value;
            }
            .panel-#{$color_name} {
                border-color: $color_value;
                .panel-heading {
                    color: #FFF;
                    border-color: $color_value;
                    background-color: lighten($color_value, 5%);
                }
            }
            .bg-#{$color_name},
            .border-#{$color_name} {
                &.tag-glow {
                    box-shadow: 0px 0px 10px $color_value;
                }
            }
            .overlay-#{$color_name} {
                @include bg-opacity($color_value, 0.8);
            }
            .card.card-outline-#{$color_name} {
                border-width: 1px;
                border-style: solid;
                border-color: $color_value;
                background-color: transparent;
                .card-header,
                .card-footer {
                    background-color: transparent;
                }
            }
            .btn-#{$color_name} {
                &.btn-flat {
                    background-color: transparent !important;
                    color: $color_value;
                    border: none;
                }
                &.btn-raised,
                &.btn-fab {
                    background-color: $color_value !important;
                    color: #fff !important;
                    border-color: $color_value;
                    &.active {
                        background-color: darken($color_value, 7%) !important;
                        border-color: darken($color_value, 7%) !important;
                    }
                }
                color: $color_value !important;
            }
            .btn-group-raised {
                .btn-#{$color_name} {
                    background-color: $color_value !important;
                    color: #fff !important;
                }
            }
            .btn-outline-#{$color_name} {
                border: 1px solid;
                border-color: $color_value;
                background-color: transparent;
                color: $color_value;
                box-shadow: none !important;
                &:focus {
                    background-color: transparent !important;
                    color: $color_value !important;
                    box-shadow: transparent !important;
                }
                &.active {
                    background-color: $color_value !important;
                    color: #FFF !important;
                }
                &:hover {
                    background-color: darken($color_value, 3%) !important;
                    color: #FFF !important;
                }
            }
            .btn-outline-#{$color_name} {
                &.btn-raised,
                &.btn-fab {
                    border: 1px solid;
                    border-color: $color_value;
                    background-color: transparent;
                    color: $color_value;
                    box-shadow: none !important;
                    &:focus {
                        background-color: transparent;
                    }
                    &.active {
                        background-color: $color_value !important;
                        color: #FFF !important;
                    }
                    &:hover {
                        background-color: darken($color_value, 3%) !important;
                        color: #FFF !important;
                    }
                }
            }
            .progress {
                .progress-bar {
                    &.progress-bar-#{$color_name} {
                        @include progress-variant($color_value);
                    }
                }
            }
            input:focus~.bg-#{$color_name} {
                box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem $color_value !important;
            }
        }
        @else {
            .#{$color_name}.#{$color_type} {
                color: $color_value !important;
            }
            .bg-#{$color_name}.bg-#{$color_type} {
                background-color: $color_value !important;
            }
            .btn-#{$color_name}.btn-#{$color_type} {
                border-color: color-function($color_name, "darken-2") !important;
                background-color: $color_value !important;
                &:hover {
                    border-color: color-function($color_name, "darken-2") !important;
                    background-color: color-function($color_name, "darken-3") !important;
                }
                &:focus,
                &:active {
                    border-color: color-function($color_name, "darken-3") !important;
                    background-color: color-function($color_name, "darken-4") !important;
                }
            }
            .btn-outline-#{$color_name}.btn-outline-#{$color_type} {
                border-color: $color_value !important;
                color: $color_value !important;
                &:hover {
                    background-color: $color_value !important;
                }
            }
            .progress-#{$color_name}.progress-#{$color_type} {
                @include progress-variant($color_value);
            }
            input:focus~.bg-#{$color_name} {
                box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem $color_value !important;
            }
            .border-#{$color_name}.border-#{$color_type} {
                border: 1px solid $color_value !important;
            }
            .border-top-#{$color_name}.border-top-#{$color_type} {
                border-top: 1px solid $color_value !important;
            }
            .border-bottom-#{$color_name}.border-bottom-#{$color_type} {
                border-bottom: 1px solid $color_value !important;
            }
            .border-left-#{$color_name}.border-left-#{$color_type} {
                border-left: 1px solid $color_value !important;
            }
            .border-right-#{$color_name}.border-right-#{$color_type} {
                border-right: 1px solid $color_value !important;
            }
            .overlay-#{$color_name}.overlay-#{$color_type} {
                @include bg-opacity($color_value, 0.8);
            }
        }
    }
}

// Shade classes
@each $color,
$color_value in $shades {
    .#{$color} {
        color: $color_value;
    }
    .bg-#{$color} {
        background-color: $color_value;
    }
    input:focus~.bg-#{$color} {
        // TODO: Need to check input-type sibling border color issue(form>input-groups)
        box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem $color_value;
    }
    .border-#{$color} {
        border: 1px solid $color_value;
    }
    .border-top-#{$color} {
        border-top: 1px solid $color_value;
    }
    .border-bottom-#{$color} {
        border-bottom: 1px solid $color_value;
    }
    .border-left-#{$color} {
        border-left: 1px solid $color_value;
    }
    .border-right-#{$color} {
        border-right: 1px solid $color_value;
    }
}
