/*
bootstrap table theme
*/

.ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: 1rem;
    .datatable-header {
      height: unset !important;
      .datatable-header-cell {
        vertical-align: bottom;
        padding: 0.75rem;
        border-bottom: 1px solid #d1d4d7;
        .datatable-header-cell-label {
          line-height: 24px;
        }
      }
    }
    .datatable-body {
      .datatable-body-row {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #d1d4d7;
        &.datatable-row-even {
          background-color: rgba(0, 0, 0, 0.05);
        }
        &.active {
          background-color: #1483ff;
          color: #FFF;
        }
        .datatable-body-cell {
          text-align: left;
          vertical-align: top;
        }
      }
    }
    .datatable-footer {
      background: #424242;
      color: #ededed;
      margin-top: -1px;
      .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem;
      }
      .datatable-pager {
        margin: 0 10px;
        vertical-align: top;
        ul {
          li {
            margin: 10px 0px;
            &:not(.disabled) {
              &.active,
              &:hover {
                a {
                  background-color: #545454;
                  font-weight: bold;
                }
              }
            }
          }
        }
        a {
          height: 22px;
          min-width: 24px;
          line-height: 22px;
          padding: 0;
          border-radius: 3px;
          margin: 0 3px;
          text-align: center;
          vertical-align: top;
          text-decoration: none;
          vertical-align: bottom;
          color: #ededed;
        }
        .datatable-icon-left,
        .datatable-icon-skip,
        .datatable-icon-right,
        .datatable-icon-prev {
          font-size: 18px;
          line-height: 27px;
          padding: 0 3px;
        }
      }
    }
  }