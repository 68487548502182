@import "scss/variables";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "scss/core/palette";
@import "scss/app-variables";
@import "scss/mixins";
@import "scss/custom-bootstrap";
@import "scss/core";
@import "scss/fonts/weather-icons";
@import "scss/plugins";
// @import "scss/demo";  // Remove this SCSS from production

.ngx-datatable .datatable-footer .datatable-pager .pager li,
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
    outline: none;
    line-height: 1.4em !important;
}
.datatable-body-cell {
    &.dropmenu {
        overflow: visible !important;
        .datatable-body-cell-label {
            overflow: unset;
            .dropdown-menu {
                z-index: 999;
                background-color: #fff;
            }
        }
    }
}
.dropdown-table {
    &.ngx-datatable {
        overflow: visible !important;
        overflow-x: visible !important;
    }
}

i {
    vertical-align: middle;
    line-height: 1.2;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    white-space: normal !important;
}

.empty-row {
    padding: 20px;
}

.black-overlay {
    position: fixed !important;
}

.app-sidebar .navigation li ul li a,
.off-canvas-sidebar .navigation li ul li a {
    padding: 10px 30px 10px 25px;
}

.app-sidebar .navigation li ul li ul li a,
.off-canvas-sidebar .navigation li ul li ul li a {
    padding: 8px 18px 8px 50px;
}

.hide-app-navbar app-navbar {
    display: none;
}

.cert-popover .popover-body {
    padding: 0px;
}

.cabinetModal > .modal-dialog {
    // max-width: 90vw !important;
    max-width: 90% !important;
    width: 90% !important;
    left: 5%;
    top: 40px;
    // height: 100% !important;
    margin: 0rem !important;
    // padding-bottom: 40px;
    height: calc(100vh - 80px);
    overflow-y: hidden;
    .modal-content {
        height: 100%;
        app-user-cabinet {
            height: 100%;
        }
    }
}

.hugeModal > .modal-dialog {
    max-width: 90% !important;
    width: 90% !important;
    left: 5%;
    top: 40px;
    // height: 100% !important;
    margin: 0rem !important;
    // padding-bottom: 40px;
}

// .hugeModal > .modal-dialog .modal-content {
//     height: 100%;
// }

// .hugeModal > .modal-dialog .modal-content {
//     app-class-search {
//         height: 100%;
//     }
// }

// .hugeModal > .modal-dialog {
//     max-width: 90% !important;
//     width: 90% !important;
//     left: 5%;
// }

@media (min-width: 640px) {
    .modal-lg {
        max-width: 960px;
    }
}

@media (max-width: 991.98px) {
    .nav-btn {
        margin-top: 10px;
    }
}

@media print {
    .app-sidebar {
        display: none;
    }

    // :host ::ng-deep .ct-chart-donut {
    //     background: #000;
    //     text-align: center;
    //     display: none;
    // }
}

.pre-wrap {
    white-space: pre-wrap;
}

.user-typeahed-border {
    border-bottom: 1px solid #cccccc;
}

ngb-popover-window.search-popover {
    top: 122px;
    left: 0;
    width: 100%;
    max-width: 100%;
}
ngb-popover-window.search-popover-large {
    top: 122px;
    left: 0;
    width: 120%;
    max-width: 120%;
}
@media (min-width: 768px) {
    ngb-popover-window.search-popover-large {
        top: 122px;
        left: -10% !important;
        width: 200%;
        max-width: 200%;
    }
    ngb-popover-window.search-popover {
        top: 122px;
        left: -12% !important;
        width: 124%;
        max-width: 124%;
    }
}
.search-popover-logo-active {
    left: 0px;
    background-color: green;
    .popover-body {
        padding: 10px;
    }
}
.search-popover-logo-stopped {
    left: 0px;
    background-color: red;
    .popover-body {
        padding: 10px;
    }
}
.search-popover-logo-app {
    left: 0px;
    background-color: orange;
    .popover-body {
        padding: 10px;
    }
}

.popover-200 {
    max-width: 200px !important;
}
.popover-300 {
    max-width: 200px !important;
}
.popover-400 {
    max-width: 200px !important;
}

.letter-spacing-0 {
    a {
        span {
            letter-spacing: 0px !important;
        }
    }
}

.ng-select-container {
    height: calc(1.5em + 0.75rem + 2px) !important;
    font-size: 1rem;
}

.lang-select ng-select .ng-select-container {
    height: 48px !important;
    margin-left: 10px;
    border: none !important;
    background: transparent !important;
    &:hover {
        box-shadow: none !important;
    }
    box-shadow: none !important;
    outline: none !important;
}

.bottom-space {
    margin-bottom: 200px;
}

.btn-group {
    white-space: nowrap;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.applicants-list-popup {
    max-height: 300px;
    overflow-y: auto;
}

.ngx-datatable .datatable-body-cell.overflow-cell {
    overflow: visible;
}
